import React, { useEffect, createRef } from "react"
import LayoutTeam from "../components/layoutteam"
import Navigation from "../components/navigation"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"
import "../styles/hybrid.scss"
import scrollTo from "gatsby-plugin-smoothscroll"
import TeamFormNew from "../components/TeamFormNew"
import SEOHeader from "../components/seo-header"
import Calculator from "../components/calculator/calculator"
import passes from "../img/passes.jpg"
import pass01 from "../img/pass01.jpg"
import pass02 from "../img/pass02.jpg"
import pass03 from "../img/pass03.jpg"
import Img from "gatsby-image"
import "lazysizes"
const Passes = props => {
  const coworkingBanner = props.data.coworking.childImageSharp.fluid
  return (
    <div>
      <SEOHeader
        title="GoFloaters Passes | Work near home, Work from Anywhere"
        description="Get yourself a GoFloaters pass to gain access to 1000s of spaces instantly. No more office contracts and security deposits."
        socialURL="https://assets.gofloaters.com/passes.jpg"
        pinterest="true"
      ></SEOHeader>

      <LayoutTeam>
        <div className="PassBanner">
          <Navigation />
          <div className="container">
            <div className="row">
              <div className="col-md-4"></div>
            </div>
          </div>
          <div className="container bannerTextContent">
            <div className="row">
              <div className="col-md-7">
                <div className="TeamTextContent">
                  <h1>
                    Work near home, <span>work from anywhere</span>
                  </h1>
                  <h2 style={{ fontSize: "22px", lineHeight: "1.4" }}>
                    Get yourself a GoFloaters pass to gain access to 1000s of
                    spaces instantly. No more office contracts and security
                    deposits.
                  </h2>
                  <p>2000+ Spaces · 21 Cities · 1 Simple Pricing</p>
                  <br></br>
                  <a
                    onClick={() => scrollTo("#passes")}
                    className="teamGetStarted"
                  >
                    Get Pass Now
                  </a>
                </div>
              </div>
              <div className="col-md-5 passbannersImg">
                <Img
                  fluid={coworkingBanner}
                  className="img-responsive"
                  alt="Coworking Space"
                  width="540"
                  height="540"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row teamTrustBy">
            <div className="col-md-12">
              <br></br>
              <p>Trusted by</p>
            </div>
            <br></br>

            <div className="col-md-2 col-4 text-center img-hteams fivePixelPadding noLeftPadding">
              <img
                data-src="https://assets.gofloaters.com/teams/decalthaonlogo.svg"
                className="lazyload"
                alt="Decalthaon"
              ></img>
            </div>
            <div className="col-md-2 col-4 text-center img-hteams fivePixelPadding">
              <img
                data-src="https://assets.gofloaters.com/teams/indusind.png"
                className="lazyload"
                alt="Indusind"
              ></img>
            </div>
            {/*  <div className="col-md-2 col-4 text-center img-hteams fivePixelPadding">
                  <img
                    src="https://assets.gofloaters.com/teams/nippon.png"
                    alt="Nippon"
                  ></img>
                </div>
                <div className="col-md-2 col-4 text-center img-hteams fivePixelPadding">
                  <img
                    src="https://assets.gofloaters.com/teams/homelane.png"
                    alt="Homelane"
                  ></img>
  </div> */}
            <div className="col-md-2 col-4 text-center img-hteams fivePixelPadding">
              <img
                data-src="https://assets.gofloaters.com/clients/shriram.png"
                className="lazyload"
                alt="Shriram"
              ></img>
            </div>
            <div className="col-md-2 col-4 text-center img-hteams fivePixelPadding">
              <img
                data-src="https://assets.gofloaters.com/clients/notion-press.png"
                className="lazyload"
                alt="Notion Press"
              ></img>
            </div>

            <div className="col-md-2 col-4 text-center img-hteams fivePixelPadding">
              <img
                data-src="https://assets.gofloaters.com/teams/think-music.jpg"
                className="lazyload"
                alt="Think Music"
              ></img>
            </div>
          </div>
        </div>
        <div className="container" id="howwork">
          <div className="row officeNeed teamPadding teamHowWeWork">
            <div className="col-md-12 text-center">
              <h2>Office when you need it where you need it!</h2>
            </div>
            <div className="col-md-12 text-center">
              <p>
                With GoFloaters Pass get access to India’s largest network of
                coworking spaces. One pass to unshackle yourself from hefty
                office rents and contracts. Gift yourself stress-free productive
                workspaces. Find spaces near home, socialise and be at your
                productive best.
              </p>
            </div>
            <div className="col-md-4  downSection">
              <div className="custommargin">
                <div>
                  <img src={pass01} className="img-responsive" alt="user"></img>
                </div>
                <h3>Reduce your overheads</h3>
                <p>Never pay monthly rents, just pay-per-use</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="custommargin">
                <div>
                  <img src={pass02} className="img-responsive" alt="user"></img>
                </div>

                <h3>Improve productivity</h3>
                <p>Get away from work from home challenges</p>
              </div>
            </div>
            <div className="col-md-4 downSection">
              <div className="custommargin">
                <div>
                  <img src={pass03} className="img-responsive" alt="user"></img>
                </div>

                <h3>Get the best of bothworlds</h3>
                <p>Productivity of office without the commute</p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ background: "#F6F9FF" }} id="signup">
          <div className="container">
            <div className="row teamPadding">
              <div className="col-md-12 text-center gofloaterHomenothing">
                <h2>Get freedom and flexibility</h2>
                <p>Don’t take our word for it</p>
                <br></br>
              </div>
              <div className="col-md-4">
                <div className="HomeTestimonailCard">
                  <div className="hometextWrapper">
                    <h4>Freedom to set up my office</h4>
                    <p>
                      Gives me the freedom to set up my office across cities
                    </p>
                  </div>
                  <span className="MarkersHomeTestimonailCard"></span>
                  <div className="homelogoWrapper">
                    <picture>
                      <source
                        srcSet="https://assets.gofloaters.com/webp/vinay-004.webp"
                        type="image/webp"
                      />
                      <source
                        data-src="https://assets.gofloaters.com/teams/newhome/vinay-004.jpg"
                        type="image/jpeg"
                        className="lazyload"
                      />
                      <img
                        data-src="https://assets.gofloaters.com/teams/newhome/vinay-004.jpg"
                        alt="Vinay Pushpakaran"
                        className="lazyload"
                        width="75"
                        height="75"
                      ></img>
                    </picture>

                    <p className="text-center">Vinay Pushpakaran</p>
                    <span>Future Impact Learning</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="HomeTestimonailCard">
                  <div className="hometextWrapper">
                    <h4>Plug and Play</h4>
                    <p>Never have to worry about Internet or Plug Points.</p>
                  </div>
                  <span className="MarkersHomeTestimonailCard"></span>
                  <div className="homelogoWrapper">
                    <picture>
                      <source
                        srcSet="https://assets.gofloaters.com/webp/arpita-002.webp"
                        type="image/webp"
                      />
                      <source
                        data-src="https://assets.gofloaters.com/teams/newhome/arpita-002.jpg"
                        type="image/jpeg"
                        className="lazyload"
                      />
                      <img
                        data-src="https://assets.gofloaters.com/teams/newhome/arpita-002.jpg"
                        className="lazyload"
                        alt="Arpitha Ramani"
                        width="75"
                        height="75"
                      ></img>
                    </picture>

                    <p className="text-center">Arpitha Ramani </p>
                    <span>GE Healthcare</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="HomeTestimonailCard">
                  <div className="hometextWrapper">
                    <h4>External meeting made easy</h4>
                    <p>My go-to platform for all our external meetings</p>
                  </div>
                  <span className="MarkersHomeTestimonailCard"></span>
                  <div className="homelogoWrapper">
                    <picture>
                      <source
                        srcSet="https://assets.gofloaters.com/webp/balesh-001.webp"
                        type="image/webp"
                      />
                      <source
                        data-src="https://assets.gofloaters.com/teams/newhome/balesh-001.jpg"
                        className="lazyload"
                        type="image/jpeg"
                      />
                      <img
                        data-src="https://assets.gofloaters.com/teams/newhome/balesh-001.jpg"
                        className="lazyload"
                        width="75"
                        height="75"
                        alt="Balesh Raghurajan"
                      ></img>
                    </picture>

                    <p className="text-center">Balesh Raghurajan</p>
                    <span>Effilor Consulting Services</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row teamPadding TeamsWorkFromHome">
            <div className="col-md-12 text-center">
              <h2>Working from home or living from work?</h2>
              <br></br>
              <p className="TeamsWorkFromHomePara">
                Individuals are over-working and reporting higher levels of
                fatigues when working from home.<br></br> Burnout is a very real
                challenge in a remote-first world. Not anymore.
              </p>
            </div>
            <div className="col-md-4 text-center TeamsWorkFromHomePoint">
              <img
                data-src="https://assets.gofloaters.com/teams/officespacegotbetter.svg"
                alt="officespacegotbetter"
                className="lazyload"
                width="45"
              ></img>
              <h3>Office space got better</h3>
              <p>
                Eliminate advances, contracts, coworking rents, utililty charges
                etc with our flat pricing.
              </p>
            </div>
            <div className="col-md-4 text-center TeamsWorkFromHomePoint">
              <img
                data-src="https://assets.gofloaters.com/teams/countrywise.svg"
                alt="countrywise"
                className="lazyload"
                width="45"
              ></img>
              <h3>Countrywide network</h3>
              <p>
                Get instant access to 3000+ vetted workspaces across 40+ cities,
                pan-India.
              </p>
            </div>
            <div className="col-md-4 text-center TeamsWorkFromHomePoint">
              <img
                data-src="https://assets.gofloaters.com/teams/fullyloadedspaces.svg"
                alt="Fullyloadedspaces"
                className="lazyload"
                width="45"
              ></img>
              <h3>Fully loaded spaces</h3>
              <p>
                Never worry about fast internet, parking, pantry, utilities and
                furniture. We cover it all.
              </p>
            </div>
            <div className="col-md-4 text-center TeamsWorkFromHomePoint">
              <img
                data-src="https://assets.gofloaters.com/teams/prioritysupport.svg"
                alt="Priority Support"
                className="lazyload"
                width="45"
              ></img>
              <h3>Priority Support 24x7</h3>
              <p>
                We’re always available to ensure your team-mates have the best
                experience.
              </p>
            </div>
            <div className="col-md-4 text-center TeamsWorkFromHomePoint">
              <img
                data-src="https://assets.gofloaters.com/teams/500booking.svg"
                className="lazyload"
                alt="One Bill"
                width="45"
              ></img>
              <h3>Get an office instantly</h3>
              <p>
                Book your space instantly and work near home when you want and
                for the time you want.
              </p>
            </div>
            <div className="col-md-4 text-center TeamsWorkFromHomePoint">
              <img
                data-src="https://assets.gofloaters.com/teams/covid.svg"
                className="lazyload"
                alt="Covid"
                width="45"
              ></img>
              <h3>Vetted for COVID Safety </h3>
              <p>
                We make sure that partner spaces comply with stringent
                precautions against COVID-19.
              </p>
            </div>
          </div>
        </div>
        <div style={{ background: "#F6F9FF" }}>
          <div className="container">
            <div className="row teamPadding">
              <div className="col-md-4">
                <div className="teamTestimonailCard">
                  <div className="textWrapper">
                    <h4>Re-inventing coworking</h4>
                    <p>
                      GoFloaters has doubled down on serving India’s 15 million
                      freelancers, and creating a co-work ecosystem powerfully
                      returning co-working to its initial vision
                    </p>
                  </div>
                  <div className="TeamslogoWrapper">
                    <img
                      src="https://assets.gofloaters.com/teams/forbes.png"
                      alt="Forbes"
                      className="forbes"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="teamTestimonailCard">
                  <div className="textWrapper">
                    <h4>Uber for the Gig Economy</h4>
                    <p>
                      Discovering a property that is most suitable - and
                      affordable - can often be tedious. But, we found a fix in
                      the GoFloaters app.
                    </p>
                  </div>
                  <div className="TeamslogoWrapper">
                    <img
                      data-src="https://assets.gofloaters.com/teams/yourstory.png"
                      alt="Your Story"
                      className="lazyload yourstory"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="teamTestimonailCard">
                  <div className="textWrapper">
                    <h4>A sustainable work model</h4>
                    <p>
                      Pay-as-you-use service app that tech start ups,
                      freelancers, artists, hobbyists and sales and enterprise
                      teams can use,
                    </p>
                  </div>
                  <div className="TeamslogoWrapper">
                    <img
                      data-src="https://assets.gofloaters.com/teams/the-hindu.png"
                      alt="The Hindu"
                      className="lazyload hindus"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row teamPadding">
            <div className="col-md-6 teamFormWorkSpace">
              <h2>Remote work ≠ Working from home</h2>
              <p>
                Let’s face it. Working from home is stressful, distracting and
                lonely for most people. Sure, things get done. Change the scene,
                step out and work near home to have the best of both worlds.
                <br />
              </p>
              <br />
            </div>
            <div className="col-md-6">
              <div className="row">
                <br></br>
                <div className="col-md-6 TeamsSellingPoint">
                  <div className="points">
                    <div className="row">
                      <div className="col-md-3 col-2 text-center">
                        <img
                          data-src="https://assets.gofloaters.com/teams/1200spaces.svg"
                          alt="2000 Spaces"
                          className="lazyload"
                        ></img>
                      </div>
                      <div className="col-md-9 col-10">
                        <h4>2000+</h4>
                        <p>Vetted Workspaces</p>
                      </div>
                    </div>
                  </div>
                  <div className="points">
                    <div className="row">
                      <div className="col-md-3 col-2 text-center">
                        <img
                          data-src="https://assets.gofloaters.com/teams/500booking.svg"
                          alt="500 Booking"
                          className="lazyload"
                        ></img>
                      </div>
                      <div className="col-md-9 col-10">
                        <h4>500+</h4>
                        <p>Bookings per day</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 TeamsSellingPoint">
                  <div className="points">
                    <div className="row">
                      <div className="col-md-3 col-2 text-center">
                        <img
                          data-src="https://assets.gofloaters.com/teams/14cities.svg"
                          alt="40+ Cities"
                          className="lazyload"
                        ></img>
                      </div>
                      <div className="col-md-9 col-10">
                        <h4>40+</h4>
                        <p>Cities in India</p>
                      </div>
                    </div>
                  </div>
                  <div className="points">
                    <div className="row">
                      <div className="col-md-3 col-2 text-center">
                        <img
                          data-src="https://assets.gofloaters.com/teams/15000customers.svg"
                          alt="15000 Customers"
                          className="lazyload"
                        ></img>
                      </div>
                      <div className="col-md-9 col-10">
                        <h4>15,000+</h4>
                        <p>Customers</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row teamPadding">
            <div className="col-md-6 text-center">
              <img
                data-src="https://assets.gofloaters.com/teams/indiamap.png"
                alt="Indian Map"
                className="lazyload img-responsive"
              ></img>
            </div>
            <div className="col-md-5 teamFormWorkSpace SupoD pad120">
              <h2>
                That’s why we built the largest workspace network in the country
              </h2>
              <p>
                Get a flexible, plug & play workspace when you want, where you
                want for the time you want. Office just got better with no
                contracts and security deposits.
              </p>

              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
        {/* <div className="" id="unlockDeals">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>Unlock exclusive deals when you sign up</h2>
                <p>
                  We offer ₹1.5 Crores in free benefits and discounts for
                  eligible startups* through our partner program
                </p>
              </div>
            </div>
            <div className="row MobileOnly">
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/todoist.svg"
                    className="lazyload height"
                    alt="todoist"
                    width="108"
                    height="30"
                  ></img>
                  <p>1 free year of Business plan</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/miro.svg"
                    className="lazyload width"
                    alt="miro"
                    width="90"
                    height="32"
                  ></img>
                  <p>$1000 in credits</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/aws.png"
                    className="lazyload height"
                    alt="aws"
                    width="50"
                    height="30"
                  ></img>
                  <p>$1000 credit for 2 years</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/razorpay.png"
                    className="lazyload width"
                    alt="razorpay"
                    width="90"
                    height="19"
                  ></img>
                  <p>Zero transaction fee upto ₹4L</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/hubspot.png"
                    className="lazyload height"
                    alt="hubspot"
                    width="103"
                    height="30"
                  ></img>
                  <p>90% off on yearly plans</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/udemy.png"
                    className="lazyload height"
                    alt="udemy"
                    width="103"
                    height="30"
                  ></img>
                  <p>Upto 95% off on courses</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/segment.svg"
                    className="lazyload height"
                    alt="segment"
                    width="108"
                    height="30"
                  ></img>
                  <p>
                    $50,000 in credits + discounts on 35+ products worth $1M
                  </p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/typeform.png"
                    className="lazyload height"
                    alt="typeform"
                    width="47"
                    height="30"
                  ></img>
                  <p>50% of monthly & yearly plans</p>
                </div>
              </div>
              <div className="col-6">
                <div className="partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/kaleyra.png"
                    className="lazyload height"
                    alt="kaleyra"
                    width="124"
                    height="30"
                  ></img>
                  <p>₹8000 credit for 1 year</p>
                </div>
              </div>
            </div>
            <div className="row gofloaterHomePartner DesktopOnly">
              <div className="col-md-1"></div>
              <div className="col-md-2">
                <div className="column1 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/todoist.svg"
                    className="lazyload height"
                    alt="todoist"
                    width="108"
                    height="30"
                  ></img>
                  <p>1 free year of Business plan</p>
                </div>
              </div>
              <div className="col-md-2 columns2">
                <div className=" column2 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/miro.svg"
                    className="lazyload width"
                    alt="miro"
                    width="90"
                    height="32"
                  ></img>
                  <p>$1000 in credits</p>
                </div>
                <div className="column2 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/aws.png"
                    className="lazyload height"
                    alt="aws"
                    width="50"
                    height="30"
                  ></img>
                  <p>$1000 credit for 2 years</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="column3 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/razorpay.png"
                    className="lazyload width"
                    alt="razorpay"
                    width="90"
                    height="19"
                  ></img>
                  <p>Zero transaction fee upto ₹4L</p>
                </div>
                <div className="column3 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/hubspot.png"
                    className="lazyload height"
                    alt="hubspot"
                    width="103"
                    height="30"
                  ></img>
                  <p>90% off on yearly plans</p>
                </div>
                <div className="column3 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/udemy.png"
                    className="lazyload height"
                    alt="udemy"
                    width="103"
                    height="30"
                  ></img>
                  <p>Upto 95% off on courses</p>
                </div>
              </div>
              <div className="col-md-2 columns2">
                <div className=" column4 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/segment.svg"
                    className="lazyload height"
                    alt="segment"
                    width="108"
                    height="30"
                  ></img>
                  <p>
                    $50,000 in credits + discounts on 35+ products worth $1M
                  </p>
                </div>
                <div className="column4 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/typeform.png"
                    className="lazyload height"
                    alt="typeform"
                    width="47"
                    height="30"
                  ></img>
                  <p>50% of monthly & yearly plans</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="column5 partnerOffer">
                  <img
                    data-src="https://assets.gofloaters.com/teams/newhome/logos/kaleyra.png"
                    className="lazyload height"
                    alt="kaleyra"
                    width="124"
                    height="30"
                  ></img>
                  <p>₹8000 credit for 1 year</p>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <p>&nbsp;</p>
                <Link
                  to="/our-community-perks/"
                  title="Our Community Perks"
                  style={{ color: "#303030", fontWeight: "bold" }}
                >
                  ...and many more
                </Link>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container padding60" id="passes">
          <div className="row">
            <div className="col-md-12">
              <br></br>
            </div>
            <div className="col-md-12 teamFormWorkSpace ">
              <h2>GoFloaters Passes</h2>
            </div>
            {/* <div className="col-md-12">
              <table className="table priceTable">
                <tr>
                  <th>Number of Days</th>
                  <th>Bonus Days</th>
                  <th>Total Days</th>
                  <th>Community Benefits</th>
                  <th>Validity</th>
                  <th>Price *</th>
                </tr>
                <tr>
                  <td>5 days</td>
                  <td>0</td>
                  <td>5 days</td>
                  <td>Yes</td>
                  <td>No expiry</td>
                  <td>Rs. 1250 + GST</td>
                </tr>
                <tr>
                  <td>10 days</td>
                  <td>0</td>
                  <td>10 days</td>
                  <td>Yes</td>
                  <td>No expiry</td>
                  <td>Rs. 2500 + GST</td>
                </tr>
                <tr>
                  <td>27 days</td>
                  <td>3 days</td>
                  <td>30 days</td>
                  <td>Yes</td>
                  <td>No expiry</td>
                  <td>Rs. 6750 + GST</td>
                </tr>
              </table>
</div>*/}
            <div className="col-md-4">
              <div className="priceCard downTen">
                <div className="priceValue">5 Day Pass</div>
                <div className="packageBenefits">
                  <b>5</b> <span>days of hot desk use</span>
                </div>
                <div className="packageBenefits">No bonus days</div>
                <div className="packageBenefits">
                  Perks/Discounts <br></br>worth over ₹1.5 Crores
                </div>
                <div className="packageBenefits">No expiry of credits</div>
                <div className="packageBenefits bold">₹ 1250 + GST</div>
                <div className="packageBenefitsEnd">
                  <br></br>
                  <a
                    href="https://rzp.io/l/IYUSZCjd6"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className="searchWorkspace"
                  >
                    Buy Now
                  </a>{" "}
                  <br></br> <br></br>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <span className="popularBadge">Most Popular</span>
              <div className="priceCard">
                <div className="priceValue bonusPlan">30 Day Pass</div>
                <div className="packageBenefits">
                  <b>30</b> <span>days of hot desk use</span>
                </div>
                <div className="packageBenefits">
                  Pay for 27 days and get 3 bonus days
                </div>
                <div className="packageBenefits">
                  Perks/Discounts<br></br> worth over ₹1.5 Crores
                </div>
                <div className="packageBenefits">No expiry of credits</div>
                <div className="packageBenefits bold">₹ 6750 + GST</div>
                <div className="packageBenefitsEnd">
                  <br></br>
                  <a
                    href="https://rzp.io/l/ohcjvslWCH"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className="searchWorkspace"
                  >
                    Buy Now
                  </a>{" "}
                  <br></br> <br></br>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="priceCard downTen">
                <div className="priceValue">10 Day Pass</div>
                <div className="packageBenefits">
                  <b>10</b>
                  <span>days of hot desk use</span>
                </div>
                <div className="packageBenefits">No bonus days</div>{" "}
                <div className="packageBenefits">
                  Perks/Discounts <br></br>worth over ₹1.5 Crores
                </div>
                <div className="packageBenefits">No expiry of credits</div>
                <div className="packageBenefits bold">₹ 2500 + GST</div>
                <div className="packageBenefitsEnd">
                  <br></br>
                  <a
                    href="https://rzp.io/l/mY4k3qIB4"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className="searchWorkspace"
                  >
                    Buy Now
                  </a>{" "}
                  <br></br> <br></br>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <br></br>
              <br></br>
              <p style={{ fontSize: "14px" }}>
                ** You get the number of days mentioned above when you book hot
                desks priced at ₹ 250 / day.
              </p>
            </div>
          </div>
        </div>
      </LayoutTeam>
    </div>
  )
}
export default Passes
export const query = graphql`
  query PassesCity {
    coworking: file(relativePath: { eq: "coworking/coworking-space.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
